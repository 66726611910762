import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppRouter from "./components/AppRouter/AppRouter";
import "./App.css";
import MainPage from "./pages/MainPage/MainPage";
import { servicePageArr } from "./utils/servisePageArr";
import ServicePage from "./pages/ServicePage/ServicePage";
import { ABOUTE_ROUTE, CONTACTS_ROUTE, PRICES_ROUTE } from "./utils/routes";
import AboutePage from "./pages/AboutePage/AboutePage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import { useDispatch, useSelector } from "react-redux";
import { setDarkTheme, setLightTheme } from "./redux/ToggleThemeSlice/ToggleThemeSlice";
import PricesPage from "./pages/PricesPage/PricesPage";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const dispatch = useDispatch();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = (e) => {
      if (e.matches) {
        dispatch(setDarkTheme());
      } else {
        dispatch(setLightTheme());
      }
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [dispatch]);

  return (
    <HelmetProvider>
    <div className={`App ${isDarkTheme ? "" : "light"}`}>
      <Router>
        <Routes>
          <Route path="/" element={<AppRouter />}>
            <Route index element={<MainPage />} />
            <Route path={ABOUTE_ROUTE} element={<AboutePage />} />
            <Route path={CONTACTS_ROUTE} element={<ContactsPage />} />
            <Route path={PRICES_ROUTE} element={<PricesPage serviceArr={servicePageArr}/>} />
            {servicePageArr.map((page, idx) => {
              return (
                <Route
                  key={idx}
                  path={page.path}
                  element={
                    <ServicePage coverDescription={page.coverDescription} servicePhotos={page.aboutePhotos}
                    serviceTexts={page.abouteTexts}
                    serviceTitle={page.abouteTitle} pricesObj={page.pricesObj}
                    metaTitle={page.metaTitle}
                    metaDescription={page.metaDescription}
                    metaUrl={page.metaUrl}
                    />
                  }
                />
              );
            })}
          </Route>
        </Routes>
      </Router>
    </div>
    </HelmetProvider>
  );
}

export default App;
