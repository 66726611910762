import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { setBurgerIsClose } from '../../redux/BurgerSlice/BurgerSlice';
import { ABOUTE_ROUTE, CONTACTS_ROUTE, PRICES_ROUTE, SERVICE_COSMETOLOGY_ROUTE, SERVICE_GINECOLOGY_ROUTE, SERVICE_PASTIC_ROUTE, SERVICE_PROCTOLOGY_ROUTE, SERVICE_VESSELS_ROUTE } from '../../utils/routes';
import './Burger.css'

function Burger() {
    const dispatch = useDispatch()
    const isActive = useSelector((state) => state.burger.isBurgerActive)

   const handleBurgerClose = () => {
    dispatch(setBurgerIsClose())
   }

    return (
        <div className={`burger__menu ${isActive ? "active" : ""}`}>
            <div className="burger__overlay">
                <div className="burger__body">
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={SERVICE_PASTIC_ROUTE}>Пластична хірургія</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={SERVICE_GINECOLOGY_ROUTE}>Гінекологія</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={SERVICE_COSMETOLOGY_ROUTE}>Косметологія та дерматологія</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={SERVICE_PROCTOLOGY_ROUTE}>Проктологія</Link>
                    {/* <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={SERVICE_VESSELS_ROUTE}>УЗ діагностика</Link> */}
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={ABOUTE_ROUTE}>Про нас</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={PRICES_ROUTE}>Ціни</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={CONTACTS_ROUTE}>Контакти</Link>
                </div>
            </div>
        </div>
    )
}

export default Burger