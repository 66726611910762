import { Link } from "react-router-dom";
import "./MainServices.css";
import { useSelector } from "react-redux";
import { SERVICE_COSMETOLOGY_ROUTE, SERVICE_GINECOLOGY_ROUTE, SERVICE_PASTIC_ROUTE, SERVICE_PROCTOLOGY_ROUTE, SERVICE_VESSELS_ROUTE } from "../../../utils/routes";

const MainServices = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  return (
    <section className={`main__services ${isDarkTheme ? "" : "light"}`}>
      <h2 className={`main__services-title arial-r ${isDarkTheme ? "" : "light"}`}>НАПРЯМКИ РОБОТИ</h2>
      <div className="main__services-wrapper">
        <Link to={SERVICE_PASTIC_ROUTE} className={`service ${isDarkTheme ? "" : "light"}`}>
          <h3 className="service__title mont-m">Пластична хірургія</h3>
          <p className="service__description">
          Як пластична хірургія може змінити ваше життя на краще? Якщо ваш зовнішній вигляд вас не задовольняє. Якщо ваш внутрішній світ не сприймає вашу зовнішність, ваше тіло, то ви в правильному місці. Адже ми вважаємо, що саме Ви заслуговуєте бути щасливими та успішними.
          </p>
          <div className="service__footer">
            <p className="service__footer-text mont-m">Детальніше про напрям</p>
            <div className="service__footer-icon-wrapper"></div>
          </div>
        </Link>
        <Link to={SERVICE_GINECOLOGY_ROUTE} className={`service ${isDarkTheme ? "" : "light"}`}>
          <h3 className="service__title mont-m">Гінекологія</h3>
          <p className="service__description">
          Чи знаєте ви, як часто кожна жінка повинна відвідувати гінеколога? Мінімум 1 раз на рік . Для того, щоб виявляти відхилення від норми на ранніх стадіях та проводити профілактику різних захворювань.
          </p>
          <div className="service__footer">
            <p className="service__footer-text mont-m">Детальніше про напрям</p>
            <div className="service__footer-icon-wrapper"></div>
          </div>
        </Link>
        <Link to={SERVICE_COSMETOLOGY_ROUTE} className={`service ${isDarkTheme ? "" : "light"}`}>
          <h3 className="service__title mont-m">
            Косметологія та дерматологія
          </h3>
          <p className="service__description">
          Людина, яка доглядає за собою, завжди виділяється серед інших. До косметолога/дерматолога зве...
          </p>
          <div className="service__footer diff">
            <p className="service__footer-text mont-m">Детальніше про напрям</p>
            <div className="service__footer-icon-wrapper"></div>
          </div>
        </Link>
        <Link to={SERVICE_PROCTOLOGY_ROUTE} className={`service ${isDarkTheme ? "" : "light"}`}>
          <h3 className="service__title mont-m">Проктологія</h3>
          <p className="service__description">
          Найпоширеніше упередження при виникненні проблем з боку заднього проходу – це «соромно прийти на огляд». Насправді наші комплекси роблять нам ведмежу послугу. Проблема не вирішується, а тільки погіршується з року в рік. В результаті вирішити 
          </p>
          <div className="service__footer">
            <p className="service__footer-text mont-m">Детальніше про напрям</p>
            <div className="service__footer-icon-wrapper"></div>
          </div>
        </Link>
        {/* <Link to={SERVICE_VESSELS_ROUTE} className={`service ${isDarkTheme ? "" : "light"}`}>
          <h3 className="service__title mont-m">УЗ діагностика</h3>
          <p className="service__description">
            Персоналізований підхід до кожного пацієнта. Наш медичний центр
            пропонує індивідуальні програми лікування та обстеження
          </p>
          <div className="service__footer">
            <p className="service__footer-text mont-m">Детальніше про напрям</p>
            <div className="service__footer-icon-wrapper"></div>
          </div>
        </Link> */}
      </div>
    </section>
  );
};

export default MainServices;
