export const allServices = [
  {
    categoryTitle: "Косметологія",
    isComplicated: true,
    subcategorys: [
      {
        subcategoryTile: "Діагностика",
        services: [
          {
            title: "Консультація косметолога",
            price: "безкоштовно",
          },
          {
            title: "Консультація дерматолога",
            price: "500 грн",
          },
        ],
      },
      {
        subcategoryTile: "Апаратні методики",
        services: [
          {
            title: "Видалення судин діодним лазером (крила носа)",
            price: "1000 грн",
          },
          {
            title: "Видалення судин діодним лазером (ніс повністю)",
            price: "2000 грн",
          },
          {
            title: "Видалення судин діодним лазером (щоки)",
            price: "2600 грн",
          },
          {
            title:
              "Видалення судин діодним лазером (ноги, по тілу ділянка 10х10 см)",
            price: "2700 грн",
          },
          {
            title: "Видалення судин діодним лазером (підборіддя)",
            price: "1200 грн",
          },
          {
            title:
              "Видалення судин діодним лазером (ділянка над верхньою губою)",
            price: "1000 грн",
          },
          {
            title: "Видалення гемангіоми діодним лазером до 3 мм",
            price: "300 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 10 шт по тілу (за шт)",
            price: "200 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 10 шт (обличчя, статеві органи, за шт)",
            price: "250 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 20 шт по тілу (за шт)",
            price: "150 грн",
          },
          {
            title:
              "Видалення гемангіом діодним лазером від 20 шт (обличчя, статеві органи, за шт)",
            price: "200 грн",
          },
          {
            title: "Одиночний імпульс",
            price: "8 грн",
          },
          {
            title: "Гідропілінг",
            price: "900 грн",
          },
          {
            title: "RF-ліфтинг неінвазивний",
            price: "900 грн",
          },
          {
            title: "Киснева мезотерапія",
            price: "800 грн",
          },
          {
            title: "Голівудський догляд",
            price: "1200 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (ботулінотерапія)",
        services: [
          {
            title: "Ботулінотерапія BOTOX (Allergan) одна зона",
            price: "2000 грн",
          },
          {
            title: "Ботулінотерапія BOTOX (Allergan) Лоб+міжбрів’я",
            price: "3500 грн",
          },
          {
            title: "Ботулінотерапія BOTOX (Allergan) Лоб+міжбрів’я+очі",
            price: "5000 грн",
          },
          {
            title:
              "Ботулінотерапія BOTOX (Allergan) Кисетні зморшки навколо рота ",
            price: "2700 грн",
          },
          {
            title: "Ботулінотерапія BOTOX (Allergan) Full face",
            price: "7000 грн",
          },
          {
            title: "Ботулінотерапія BOTOX (Allergan) Платізма (шия)",
            price: "6000 грн",
          },
          {
            title:
              "Ботулінотерапія BOTOX (Allergan) Ліфтинг Ніфертіті (шия +корекція нижньої третини обличчя)",
            price: "8000 грн",
          },
          {
            title: "Лікування гіпергідрозу",
            price: "8000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (бланшинг)",
        services: [
          {
            title: "Бланшинг поверхневих зморшок (Belotero Balance)",
            price: "6000 грн",
          },
          {
            title: "Бланшинг поверхневих зморшок (Belotero Soft)",
            price: "6000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (контурна пластика)",
        services: [
          {
            title: "Зона губ Juvederm 0.6 мл",
            price: "4800 грн",
          },
          {
            title: "Зона губ Juvederm 1.0 мл",
            price: "6500 грн",
          },
          {
            title: "Зона вилиць Juvederm 1.0 мл",
            price: "6500 грн",
          },
          {
            title: "Зона нососльозної борозди Juvederm 0.6 мл",
            price: "5000 грн",
          },
          {
            title: "Зона нососльозної борозди Juvederm 1.0 мл",
            price: "7000 грн",
          },
          {
            title: "Зона підборіддя/нижня щелепа борозди Juvederm 1.0 мл",
            price: "7000 грн",
          },
          {
            title: "Зона підборіддя/нижня щелепа борозди Juvederm 0.6 мл",
            price: "6500 грн",
          },
          {
            title: "Розчинення філера препаратом PB-serum",
            price: "2000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ін’єкційна косметологія (Біоревіталізація/Біорепарація)",
        services: [
          {
            title: "Біоревіталізація/Біорепарація обличчя Saypha Rich 1 ml",
            price: "3000 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя Belotero Revive 1 ml",
            price: "4000 грн",
          },
          {
            title: "Біоревіталізація/Біорепарація обличчя Rejuran HB 1 ml ",
            price: "3500 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація обличчя HYALUAL Electri 1.5 ml",
            price: "2800 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація обличчя HYALUAL Smart 1.8% 1ml",
            price: "3000 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація обличчя HYALUAL booster 2.5 % 1 ml",
            price: "3200 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) TwAc 2.0 3ml  ",
            price: "4700 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Rejuran Healer 2 ml  ",
            price: "5000 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Plinest (Mastelli) 2 ml",
            price: "4200 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HP CELL Vitaran  і II 2 ml",
            price: "3200 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HP CELL Vitaran  Whitening &Anti-aging 2 мл ",
            price: "3900 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Neauvia Hidro Delux 2.5 ml",
            price: "3000 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) Newest (Mastelli) 2 ml ",
            price: "4500 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HYALUAL Smart 1.8% 2 ml",
            price: "3500 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація (обличча+шия +декольте/руки) HYALUAL booster 2.5% 2 ml",
            price: "3800 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація періорбітальної зони Plinest eye 2 ml ",
            price: "4100 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація періорбітальної зони Rejuran I  1 ml ",
            price: "3000 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація періорбітальної зони TwAc eyes1 ml",
            price: "2800 грн",
          },
          {
            title:
              "Біоревіталізація/Біорепарація періорбітальної зони HP CELL Vitaran і 1 ml ",
            price: "2300 грн",
          },
        ],
      },
      {
        subcategoryTile: "Лікування рубців полінуклеотидами",
        services: [
          {
            title: "Rejuran S (лікування рубців/стрій) 1 ml",
            price: "3000 грн",
          },
          {
            title:
              "Plinest one(лікування  стріїв,рубців та ліфтинг шкіри тіла) ",
            price: "1500 грн",
          },
        ],
      },
      {
        subcategoryTile: "Мезотерапія",
        services: [
          {
            title: "Мезотерапія періорбітальної зони Toskani ECPR ",
            price: "1500 грн",
          },
          {
            title: "Мезотерапія періорбітальної зони Dermaheal Dark Circle ",
            price: "1700 грн",
          },
          {
            title:
              "Мезотерапія волосистої частини голови Toskani Hair Cocktail Plus",
            price: "800 грн",
          },
          {
            title: "Мезотерапія волосистої частини голови DR. CYJ",
            price: "3300 грн",
          },
          {
            title: "Мезотерапія волосистої частини голови Dermaheal HL",
            price: "1000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Ліполітична терапія",
        services: [
          {
            title: "Ліполітична терапія  PBserum",
            price: "2500 грн",
          },
        ],
      },
      {
        subcategoryTile: "PRP-терапія",
        services: [
          {
            title: "PRP-терапія  обличчя (2 пробірки)",
            price: "2000 грн",
          },
          {
            title: "PRP-терапія  волосистої частини голови (2  пробірки)",
            price: "2000 грн",
          },
        ],
      },
      {
        subcategoryTile: "Доглядова косметологія",
        services: [
          {
            title: "Комбінована чистка обличчя",
            price: "850 грн",
          },
          {
            title: "Комбінована чистка обличчя + пілінги",
            price: "1000 грн",
          },
          {
            title: "Карбоксітерапія",
            price: "700 грн",
          },
          {
            title: "Експрес догляд(по типу шкіри)",
            price: "700 грн",
          },
          {
            title: "Масаж обличчя, шиї та декольте",
            price: "600 грн",
          },
          {
            title: "Вогонь та лід IS Clinical",
            price: "1300 грн",
          },
          {
            title: "Платиновий антивіковий догляд  MEDIK 8",
            price: "1000 грн",
          },
          {
            title: "Механічна чистка спини+пілінг",
            price: "1000 грн",
          },
          {
            title: "Пілінг мигдалевий",
            price: "800 грн",
          },
          {
            title: "Пілінг азелаїновий",
            price: "800 грн",
          },
          {
            title: "Пілінг освітлюючий",
            price: "800 грн",
          },
          {
            title: "Ретиноловий пілінг периорбітальної зони",
            price: "800 грн",
          },
          {
            title:
              "Поверхнево-серединний пілінг з біоревіталізуючим ефектом  Appex ",
            price: "1400 грн",
          },
          {
            title:
              "Поверхнево-серединний пілінг з біоревіталізуючим ефектом  PRX33 ",
            price: "1800 грн",
          },
        ],
      },
    ],
  },
  // {
  //   categoryTitle: "УЗ ДІАГНОСТИКА",
  //   isComplicated: false,
  //   subcategorys: [
  //     {
  //       services: [
  //           {
  //               title: "УЗД нирок",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД сечового міхура",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД нирок та сечового міхура",
  //               price: "400 грн",
  //             },
  //             {
  //               title: "УЗД передміхурової залози",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД нирок, сечового міхура та передміхурової залози",
  //               price: "600 грн",
  //             },
  //             {
  //               title: "УЗД щитоподібної залози",
  //               price: "300 грн",
  //             },
  //             {
  //               title: "УЗД органів малого тазу (трансабдомінально)",
  //               price: "400 грн",
  //             },
  //             {
  //               title: "УЗД органів малого тазу (трансвагінально)",
  //               price: "450 грн",
  //             },
  //             {
  //               title: "УЗД серця ",
  //               price: "600 грн",
  //             },
  //             {
  //               title: "УЗД серця черезстравохідне",
  //               price: "1200 грн",
  //             },
  //             {
  //               title: "УЗД серця черезстравохідне з медикаментозним сном",
  //               price: "1700 грн",
  //             },
  //             {
  //               title: "УЗД молочних залоз",
  //               price: "450 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування судин голови та шиї",
  //               price: "600 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування артерій нижніх кінцівок",
  //               price: "500 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування артерій верхніх кінцівок",
  //               price: "500 грн",
  //             },
  //             {
  //               title:
  //                 "Дуплексне (триплексне) сканування артерій та вен нижніх кінцівок",
  //               price: "700 грн",
  //             },
  //             {
  //               title:
  //                 "Дуплексне (триплексне) сканування артерій та вен верхніх кінцівок",
  //               price: "700 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування вен нижніх кінцівок",
  //               price: "500 грн",
  //             },
  //             {
  //               title: "Дуплексне (триплексне) сканування вен верхніх кінцівок",
  //               price: "500 грн",
  //             },
  //       ],
  //     },
  //   ],
  // },
  {
    categoryTitle: "ПРОКТОЛОГІЯ",
    isComplicated: false,
    subcategorys: [
      {
        services: [
            {
                title: "Консультація проктолога",
                price: "600 грн",
              },
              {
                title: "Повторна консультація проктолога (протягом 2 тижнів)",
                price: "400 грн",
              },
              {
                title:
                  "Видалення доброякісних новоутворень підвищеної складності (анальна бахромка)",
                price: "5700 грн",
              },
              {
                title: "Ректороманоскопія",
                price: "500 грн",
              },
              {
                title: "Аноскопія",
                price: "350 грн",
              },
              {
                title:
                  "Видалення зовнішнього гемороїдального вузла IІІ категорії складності",
                price: "6300 грн",
              },
              {
                title:
                  "Видалення тромбу гемороїдального вузла IІ категорії складності",
                price: "4500 грн",
              },
              {
                title:
                  "Видалення тромбу гемороїдального вузла IІІ категорії складності",
                price: "5300 грн",
              },
              {
                title: "Видалення анального поліпу I категорії складності",
                price: "3100 грн",
              },
              {
                title: "Видалення анального поліпу IІ категорії складності",
                price: "4200 грн",
              },
              {
                title: "Видалення анального поліпу IІІ категорії складності",
                price: "5300 грн",
              },
              {
                title:
                  "Видалення зовнішнього гемороїдального вузла І категорії складності",
                price: "4500 грн",
              },
              {
                title:
                  "Видалення зовнішнього гемороїдального вузла IІ категорії складності",
                price: "5300 грн",
              },
              {
                title:
                  "Видалення тромбу гемороїдального вузла I категорії складності",
                price: "3800 грн",
              },
              {
                title: "Коагуляція гіпертрофованої анальної залози (папілліт)",
                price: "3000 грн",
              },
              {
                title: "Видалення стороннього тіла з анального каналу",
                price: "5200 грн",
              },
              {
                title: "HAL-RAR 1 категорії складності",
                price: "14500 грн",
              },
              {
                title: "HAL-RAR 2 категорії складності",
                price: "17000 грн",
              },
              {
                title: "HAL-RAR 3 категорії складності",
                price: "25000 грн",
              },
              {
                title: "HAL-RAR 4 категорії складності",
                price: "38000 грн",
              },
              {
                title:
                  "Медикаментозний супровід при оперативному лікуванні проктологічної патології з використанням hal-rar",
                price: "2000 грн",
              },
              {
                title: "Оперативне лікування складних анальних тріщин (двох)",
                price: "14000 грн",
              },
              {
                title:
                  "Оперативне лікування складних анальних тріщин зі спазмом сфінктера",
                price: "15500 грн",
              },
        ],
      },
    ],
  },
  {
    categoryTitle: "ГІНЕКОЛОГІЯ",
    isComplicated: true,
    subcategorys: [
        {
            subcategoryTile: "Діагностика",
            services: [
                {
                    title: "Консультація гінеколога",
                    price: "600 грн",
                  },
                  {
                    title: "Повторна консультація гінеколога",
                    price: "400 грн",
                  },
                  {
                    title: "Кольпоскопія",
                    price: "400 грн",
                  },
                  {
                    title: "Консультація гінеколога + УЗД (трансвагінальне та/або трансабдомінальне)",
                    price: "1000 грн",
                  },
                  {
                    title: "УЗД органів малого тазу (трансвагінальне та/або трансабдомінальне)",
                    price: "400 грн",
                  },
                  {
                    title: "Рідинна цитологія ПАП-тест",
                    price: "650 грн",
                  },
                  {
                    title: "Класичний ПАП тест",
                    price: "400 грн",
                  },
                  {
                    title:
                      "Дослідження мікрофлори урогенітального тракту",
                    price: "400 грн",
                  },
                  {
                    title:
                      "Фолікулометрія",
                    price: "300 грн",
                  },
                  {
                    title: "Ехогістеросальпінгоскопія",
                    price: "2900 грн",
                  },
                  {
                    title: "Пайпель-біопсія ендометрію",
                    price: "3200 грн",
                  },
                  {
                    title: 'Введення внутрішньоматкового контрацептиву "Мірена" (контрацептив включено до вартості)',
                    price: "5700 грн",
                  },
                  {
                    title:
                      'Введення внутрішньоматкового контрацептиву "Мірена" (без вартості контрацептиву)',
                    price: "3000 грн",
                  },
                  {
                    title: "Видалення внутрішньоматкового контрацептиву",
                    price: "800 грн",
                  },
                  {
                    title: "Біопсія шийки матки з гістологічним дослідженням",
                    price: "3500 грн",
                  },
            ],
          },
        {
            subcategoryTile: "Оперативна гінекологія",
            services: [
                {
                    title: "Коагуляція шийки матки",
                    price: "3000 грн",
                  },
                  {
                    title: "Видалення папілом зовнішніх статевих органів",
                    price: "2000 грн",
                  },
                  {
                    title: "Лікувально-діагностичне вишкрібання стінок порожнини матки",
                    price: "4900 грн",
                  },
                  {
                    title: "Конізація шийки матки",
                    price: "6000 грн",
                  },
                  {
                    title: "Видалення кісти піхви",
                    price: "11900 грн",
                  },
                  {
                    title: "Видалення кісти бартолінієвої залози",
                    price: "11900 грн",
                  },
                  {
                    title: "Гістероскопія",
                    price: "11900 грн",
                  },
                  {
                    title: "Видалення плацентарного поліпу",
                    price: "11900 грн",
                  },
                  {
                    title: "Видалення поліпу ендометрію",
                    price: "11900 грн",
                  },
                  {
                    title: "Ексцизія/коагуляція ділянок ендометріозу",
                    price: "11900 грн",
                  },
                  {
                    title: "Роз’єднання або висічення маткових сінехій",
                    price: "11900 грн",
                  },
                  {
                    title: "Абляція ендометрію",
                    price: "11900 грн",
                  },
                  {
                    title: "Біопсія ендометрію",
                    price: "11900 грн",
                  },
                  {
                    title: "Висічення внутрішньоматкової перегородки (метропластика)",
                    price: "11900 грн",
                  },
                  {
                    title: "Видалення сторонніх тіл з порожнини матки",
                    price: "11900 грн",
                  },
                  {
                    title: "Видалення субмукозного вузла",
                    price: "11900 грн",
                  },
                  {
                    title: "Пластика промежини",
                    price: "18000 грн",
                  },
                  {
                    title: "Задня кольпорафія",
                    price: "28500 грн",
                  },
                  {
                    title: "Передня кольпорафія",
                    price: "24500 грн",
                  },
                  {
                    title: "Повна вагінопластика",
                    price: "47000 грн",
                  },
                  {
                    title:
                      "Медикаментозний супровід при оперативному лікуванні (наркоз)",
                    price: "2000 грн",
                  },
            ],
          },
        {
            subcategoryTile: "Естетична гінекологія",
            services: [
                {
                    title: "Лабіоплатиска (пластика статевих губ)",
                    price: "24000 грн",
                  },
                  {
                    title: "Нитьова перинеопластика (1 комплект)",
                    price: "25000 грн",
                  },
                  {
                    title: "Нитьова перинеопластика (2 комплекти)",
                    price: "30000 грн",
                  },
                  {
                    title: "Відновлення дівочої плеви",
                    price: "33000 грн",
                  },
                  {
                    title:
                      "Корекція форми великих статевих губ за допомогою філлерів (1 мл)",
                    price: "4400 грн",
                  },
                  {
                    title: "Аугментація (збільшення) точки G",
                    price: "4400 грн",
                  },
                  {
                    title: "Біоревіталізація промежини",
                    price: "2100 грн",
                  },
                  {
                    title: "Біоревіталізація піхви",
                    price: "2700 грн",
                  },
                  {
                    title: "Контурна пластика піхви за допомогою філлерів (1 мл)",
                    price: "2600 грн",
                  },
                  {
                    title:
                      "Корекція стресового нетримання сечі за допомогою філлерів (1 мл)",
                    price: "4400 грн",
                  },
                  {
                    title: "Інтимний пілінг",
                    price: "1500 грн",
                  },
                  {
                    title: "PRP – терапія",
                    price: "від 2000 грн",
                  },
                  {
                    title: "Інстиляції сечового міхура",
                    price: "2200 грн",
                  },
            ],
          },
    ],
  },
  {
    categoryTitle: "ПЛАСТИЧНА ХІРУРГІЯ",
    isComplicated: true,
    subcategorys: [
        {
            subcategoryTile: "Пластична хірургія",
            services: [
                {
                    title: "Збільшення грудей круглими імплантами",
                    price: "3000 євро",
                  },
                  {
                    title: "Збільшення грудей анатомічними імплантами",
                    price: "3500 євро",
                  },
                  {
                    title: "Підтяжка грудей без імплантів",
                    price: "3500 євро",
                  },
                  {
                    title: "Підтяжка з імплантами",
                    price: "5000 євро",
                  },
                  {
                    title: "Заміна імплантів",
                    price: "від 4000 євро",
                  },
                  {
                    title: "Абдомінопластика",
                    price: "2500 євро",
                  },
                  {
                    title: "Абдомінопластика з ліпосакцією",
                    price: "3000 євро",
                  },
                  {
                    title: "Ліпосакція живота",
                    price: "1500 євро",
                  },
                  {
                    title: "Мініабдомінопластика",
                    price: "2000 євро",
                  },
                  {
                    title: "Верхня блефаропластика",
                    price: "900 євро",
                  },
                  {
                    title: "Нижня блефаропластика",
                    price: "1100 євро",
                  },
                  {
                    title: "Кругова блефаропластика",
                    price: "1500 євро",
                  },
                  {
                    title: "Ліпофілінг параорбітальної зони/носогубні складки/вилиці",
                    price: "200 євро",
                  },
                  {
                    title: "Ліпофілінг губ",
                    price: "200-400 євро",
                  },
                  {
                    title: "Фейсліфтинг SMAS",
                    price: "2000 євро",
                  },
                  {
                    title: "MACS ліфтинг",
                    price: "2000 євро",
                  },
                  {
                    title: "Ринопластика",
                    price: "3000 євро",
                  },
                  {
                    title: "Риносептопластика",
                    price: "3000 євро",
                  },
                  {
                    title: "Отопластика однобічна",
                    price: "600 євро",
                  },
                  {
                    title: "Отопластика двобічна",
                    price: "1000 євро",
                  },
                  {
                    title: "Видалення грудок Біша",
                    price: "900 євро",
                  },
                  {
                    title: "Хейлопластика (Булхорн)",
                    price: "800 євро",
                  },
                  {
                    title: "*Розрахунок відбувається у гривні, курс обміну - актуальний курс НБУ",
                    
                  }
            ],
          },
        {
            subcategoryTile: "Дерматохірургія",
            services: [
                {
                    title: "Консультація хірурга",
                    price: "600 грн",
                  },
                  {
                    title: "Повторна консультація онкохірурга (протягом 3 місяців)",
                    price: "400 грн",
                  },
                  {
                    title:
                      "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 1 утворення",
                    price: "300 грн",
                  },
                  {
                    title:
                      "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 3 утворення",
                    price: "700 грн",
                  },
                  {
                    title:
                      "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 5 утворень",
                    price: "1000 грн",
                  },
                  {
                    title:
                      "Видалення дрібних елементів (папілом, точкових гемангіом тощо) – 10 утворень і більше",
                    price: "від 1800 грн",
                  },
                  {
                    title: "Хірургічне видалення об’ємних утворень шкіри",
                    price: "від 1500 грн",
                  },
                  {
                    title:
                      "Патогістологічне дослідження видаленого новоутворення 1-2 категорії складності (до 3 утворень включно)",
                    price: "1000 грн",
                  },
                  {
                    title:
                      "Патогістологічне дослідження видаленого новоутворення 3 категорії складності",
                    price: "1200 грн",
                  },
            ],
          },
    ],
  },
];
